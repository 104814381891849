const BASE_URL = 'https://4rooms.qatarsis.space/api';

export const fetchProperties = async () => {
  const response = await fetch(`${BASE_URL}/Properties`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return await response.json();
};

export const fetchBanner = async () => {
  const response = await fetch(`${BASE_URL}/banners/banner`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return await response.json();
};

export const fetchRooms = async (properties = '', price = '') => {
  const url = new URL(`${BASE_URL}/Rooms`);
  const params = new URLSearchParams();
  if (properties) params.append('properties', properties);
  if (price) params.append('price', price);
  url.search = params.toString();

  const response = await fetch(url);
  if (!response.ok) {
    throw new Error('Failed to fetch rooms');
  }
  return await response.json();
};
// Добавьте другие функции для других URL по мере необходимости
